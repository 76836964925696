import React from "react";
import "../../styles/Soon.css";
import logo from "../../assets/images/soon.svg";

export default function Soon() {
    return (
        <div className="logo-soon">
            <img src={logo} alt="coming soon" className="logo-soon" />
        </div>
    );
}