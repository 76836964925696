import React from "react";
import "../../styles/Events.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Soon from "../Soon/Soon";

export default function Events() {
    return (
        <div className="container-events">
            <Header />
            <Soon />
            <Footer />
        </div>
    );
}