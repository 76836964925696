import React from "react";
import "../../styles/Banner.css";
import video1 from "../../assets/videos/lastvideo.mp4";

export default function Banner() {
    return (
        <div className="container-banner">
            <video autoPlay loop muted playsInline className="video-container-banner" src={video1}></video>
        </div>
    );
}