import React, { useState, useEffect } from "react";
import "../../styles/Header.css";
import logo from "../../assets/images/logo_4.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Header() {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();

  const toggleMenu = () => {
    setOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpen]);

  return (
    <>
      <header className={`header ${isOpen ? "hidden-header" : ""}`}>
        <Link to={`/${t("routes.home")}`} aria-label={t("footer.logo_aria_label")} className="header-logo">
          <img src={logo} alt="Logo de 904fleurs" className="header-logo" />
        </Link>
        <button
          className="header-right"
          onClick={toggleMenu}
          aria-controls="MobileNavDrawer"
          title={t("header.menu_button")}
        >
          {t("header.menu_button")}
        </button>
      </header>

      {isOpen && (
        <nav className="menu">
          <button
            className="close-menu"
            onClick={toggleMenu}
            aria-label={t("header.close_menu")}
          >
            {t("header.close_menu")}
          </button>
          <ul>
            <li><Link to={`/${t("routes.about")}`}>{t("header.about")}</Link></li>
            <li><Link to={`/${t("routes.projects")}`}>{t("header.projects")}</Link></li>
            <li><Link to={`/${t("routes.contact")}`}>{t("header.contact")}</Link></li>
            <li>
              <Link to={`/${t("routes.events")}`}>{t("header.events")}</Link> <span>{t("header.coming_soon")}</span>
            </li>
            <li>
              <Link to={`/${t("routes.shop")}`}>{t("header.shop")}</Link> <span>{t("header.coming_soon")}</span>
            </li>
          </ul>
        </nav>
      )}
      {isOpen && <div className="overlay" onClick={toggleMenu}></div>}
    </>
  );
}