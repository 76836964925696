import React from "react";
import "../../styles/Footer.css";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo_4.svg";
import Clock from "./Clock";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();
  const ig1 = "https://www.instagram.com/904fleurs/";
  const igNass = "https://www.instagram.com/n_a_s_s___/";
  const igShady = "https://www.instagram.com/ogshaddy7/";
  return (
    <footer>
      <div className="footer-desktop">
        <div className="footer-logo-container">
          <Link to={`/${t("routes.home")}`} aria-label={t("footer.logo_aria_label")}>
            <img src={logo} alt="Logo de 904fleurs" />
          </Link>
        </div>
        <div className="footer-left">
          <div className="footer-legal-container">
            <ul className="footer-legal">
              <li>{t("footer.copyright")}</li>
              <li><Link to={`/${t("routes.legal_mentions")}`}>{t("footer.legal_mentions")}</Link></li>
              <li><Link to={`/${t("routes.privacy_policy")}`}>{t("footer.privacy_policy")}</Link></li>
            </ul>
          </div>
          <div className="footer-time-container">
            <ul className="footer-time">
              <li><Clock /></li>
            </ul>
          </div>
          <div className="footer-screensize-container">
            <ul className="footer-screensize">
              <li>{window.innerWidth} X {window.innerHeight}</li>
            </ul>
          </div>
          <div className="footer-socials-container">
            <ul className="footer-socials">
              <li>{t("footer.instagram")}</li>
              <li><Link target="_blank" to={ig1} rel="noreferrer">904fleurs</Link></li>
              <li><Link target="_blank" to={igNass} rel="noreferrer">Nass</Link></li>
              <li><Link target="_blank" to={igShady} rel="noreferrer">Shaddy</Link></li>
            </ul>
          </div>
          <div className="footer-menu-container">
            <ul className="footer-menu">
              <li><Link to={`/${t("routes.home")}`}>{t("footer.menu.home")}</Link></li>
              <li><Link to={`/${t("routes.about")}`}>{t("footer.menu.about")}</Link></li>
              <li><Link to={`/${t("routes.projects")}`}>{t("footer.menu.projects")}</Link></li>
              <li><Link to={`/${t("routes.contact")}`}>{t("footer.menu.contact")}</Link></li>
              <li><Link to={`/${t("routes.events")}`}>{t("footer.menu.events")}</Link></li>
              <li><Link to={`/${t("routes.shop")}`}>{t("footer.menu.shop")}</Link></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-mobile">
        <div className="footer-mobile-left-container">
          <div className="footer-mobile-legal-container">
            <ul className="footer-mobile-legal">
              <li><Clock /></li>
              <li>{window.innerWidth} X {window.innerHeight}</li>
              <li>{t("footer.copyright")}</li>
              <li><Link to={`/${t("routes.legal_mentions")}`}>{t("footer.legal_mentions")}</Link></li>
              <li><Link to={`/${t("routes.privacy_policy")}`}>{t("footer.privacy_policy")}</Link></li>
            </ul>
          </div>
        </div>
        <div className="footer-mobile-right-container">
          <div className="footer-mobile-menu-container">
            <ul className="footer-mobile-menu">
              <li><Link to={`/${t("routes.home")}`}>{t("footer.menu.home")}</Link></li>
              <li><Link to={`/${t("routes.about")}`}>{t("footer.menu.about")}</Link></li>
              <li><Link to={`/${t("routes.projects")}`}>{t("footer.menu.projects")}</Link></li>
              <li><Link to={`/${t("routes.contact")}`}>{t("footer.menu.contact")}</Link></li>
              <li><Link to={`/${t("routes.events")}`}>{t("footer.menu.events")}</Link></li>
              <li><Link to={`/${t("routes.shop")}`}>{t("footer.menu.shop")}</Link></li>
            </ul>
          </div>
          <div className="footer-mobile-socials-container">
            <ul className="footer-mobile-socials">
              <li>{t("footer.instagram")}</li>
              <li><Link target="_blank" to={ig1} rel="noreferrer">904fleurs</Link></li>
              <li><Link target="_blank" to={igNass} rel="noreferrer">Nass</Link></li>
              <li><Link target="_blank" to={igShady} rel="noreferrer">Shaddy</Link></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}