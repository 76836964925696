import React, { useState, useRef } from "react";
import "../../styles/AboutContent.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function AboutContent() {
    const { t } = useTranslation();
    const [activeSection, setActiveSection] = useState("studio");
    const sectionRefs = {
        studio: useRef(null),
        mission: useRef(null),
        valeurs: useRef(null),
        services: useRef(null),
        realisations: useRef(null),
        travailler: useRef(null),
        contact: useRef(null),
    };

    const scrollToSection = (section) => {
        setActiveSection(section);
        sectionRefs[section].current.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <div className="about-content">
            <div className="titles">
                {Object.keys(sectionRefs).map((section) => (
                    <div
                        key={section}
                        className={`title ${activeSection === section ? "active" : ""}`}
                        onClick={() => scrollToSection(section)}
                    >
                        {t(`about.titles.${section}`)}
                    </div>
                ))}
            </div>
            <div className="content">
                {Object.keys(sectionRefs).map((section) => (
                    <div
                        key={section}
                        ref={sectionRefs[section]}
                        className={`content-section ${activeSection === section ? "visible" : "hidden"
                            }`}
                    >
                        {section === "studio" && (
                            <>
                                <h2>{t("about.content.studio")}</h2>
                                <p>{t("about.content.studio_description")}</p>
                            </>
                        )}
                        {section === "mission" && (
                            <>
                                <h2>{t("about.content.mission")}</h2>
                                <p>{t("about.content.mission_description")}</p>
                            </>
                        )}
                        {section === "valeurs" && (
                            <>
                                <h2>{t("about.content.valeurs")}</h2>
                                <ul>
                                    <li>{t("about.content.valeurs_list.authenticity")}</li>
                                    <li>{t("about.content.valeurs_list.creativity")}</li>
                                    <li>{t("about.content.valeurs_list.excellence")}</li>
                                </ul>
                            </>
                        )}
                        {section === "services" && (
                            <>
                                <h2>{t("about.content.services")}</h2>
                                <ul>
                                    <li>{t("about.content.services_list.video_production")}</li>
                                    <li>{t("about.content.services_list.post_production")}</li>
                                    <li>{t("about.content.services_list.creative_direction")}</li>
                                    <li>{t("about.content.services_list.collaborations")}</li>
                                </ul>
                            </>
                        )}
                        {section === "realisations" && (
                            <>
                                <h2>{t("about.content.realisations")}</h2>
                                <p>{t("about.content.realisations_description")}</p>
                            </>
                        )}
                        {section === "travailler" && (
                            <>
                                <h2>{t("about.content.travailler")}</h2>
                                <ul>
                                    <li>{t("about.content.travailler_list.vision")}</li>
                                    <li>{t("about.content.travailler_list.custom_solutions")}</li>
                                    <li>{t("about.content.travailler_list.passion")}</li>
                                </ul>
                            </>
                        )}
                        {section === "contact" && (
                            <>
                                <h2>{t("about.content.contact")}</h2>
                                <p>{t("about.content.contact_description")}</p>
                                <div className="container-button-section">
                                <Link to={`/${t("routes.contact")}`} className="content-section-button">
                                    {t("about.content.contact")}
                                </Link>
                                </div>
                                
                            </>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}