import React from "react";
import "../../styles/Policy.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useTranslation } from "react-i18next";

export default function Policy() {
  const { t } = useTranslation();

  return (
    <div className="container-policy">
      <Header />
      <main className="policy-content">
        <h1 className="policy-title">{t("policy.title")}</h1>
        <section>
          <h2 className="section-title">{t("policy.sections.responsible.title")}</h2>
          <p>{t("policy.sections.responsible.description")}</p>
          <p>{t("policy.sections.responsible.address")}</p>
          <p>{t("policy.sections.responsible.contact")}</p>
        </section>
        <section>
          <h2 className="section-title">{t("policy.sections.data_collected.title")}</h2>
          <p>{t("policy.sections.data_collected.description")}</p>
          <ul>
            {t("policy.sections.data_collected.items", { returnObjects: true }).map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </section>
        <section>
          <h2 className="section-title">{t("policy.sections.data_usage.title")}</h2>
          <p>{t("policy.sections.data_usage.description")}</p>
          <ul>
            {t("policy.sections.data_usage.items", { returnObjects: true }).map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </section>
        <section>
          <h2 className="section-title">{t("policy.sections.data_hosting.title")}</h2>
          <p>{t("policy.sections.data_hosting.description")}</p>
        </section>
        <section>
          <h2 className="section-title">{t("policy.sections.user_rights.title")}</h2>
          <p>{t("policy.sections.user_rights.description")}</p>
          <ul>
            {t("policy.sections.user_rights.items", { returnObjects: true }).map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </section>
      </main>
      <Footer />
    </div>
  );
}