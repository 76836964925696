import React from "react";
import "../../styles/Mention.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useTranslation } from "react-i18next";

export default function Mention() {
  const { t } = useTranslation();

  return (
    <div className="container-mention">
      <Header />
      <div className="mention-content">
        <h1 className="mention-title">{t("mention.title")}</h1>
        <section>
          <h2 className="section-title">{t("mention.sections.editor.title")}</h2>
          <p>{t("mention.sections.editor.description")}</p>
          <p>{t("mention.sections.editor.address")}</p>
          <ul>
            {t("mention.sections.editor.members", { returnObjects: true }).map((member, index) => (
              <li key={index}>{member}</li>
            ))}
          </ul>
        </section>
        <section>
          <h2 className="section-title">{t("mention.sections.creation.title")}</h2>
          <p>{t("mention.sections.creation.description")}</p>
          <ul>
            {t("mention.sections.creation.contributors", { returnObjects: true }).map(
              (contributor, index) => (
                <li key={index}>{contributor}</li>
              )
            )}
          </ul>
        </section>
        <section>
          <h2 className="section-title">{t("mention.sections.hosting.title")}</h2>
          <p>{t("mention.sections.hosting.description")}</p>
          <ul>
            <li>{t("mention.sections.hosting.address")}</li>
            <li>{t("mention.sections.hosting.website")}</li>
          </ul>
        </section>
      </div>
      <Footer />
    </div>
  );
}