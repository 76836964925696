import React from 'react';
import '../../styles/Landing.css';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo4.gif';
import { useTranslation } from 'react-i18next';

export default function Landing() {
    const { t } = useTranslation();
    return (
        <div className="container">
            <Link to={`/${t("routes.home")}`} style={{ textDecoration: 'none' }}>
                <img src={logo} alt="Logo de 904fleurs" className="logo" />
            </Link>
        </div>
    );
}
