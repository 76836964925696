import React from "react";
import "../../styles/HomeContent.css";
import video1 from "../../assets/videos/1.mp4";
import video2 from "../../assets/videos/2.mp4";
import video3 from "../../assets/videos/3.mp4";
import video4 from "../../assets/videos/4.mp4";
import video5 from "../../assets/videos/5.mp4";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


export default function HomeContent() {
  const {i18n } = useTranslation();
  const videos = [
    { id: 1, src: video1, youtubeLink: "https://youtu.be/xglnlVhrDM4?si=jWLkag27ukLamWI7", slug: "comment-devenir-un-artiste" },
    { id: 2, src: video2, youtubeLink: "https://youtu.be/Hnzj0fIuD1s?si=hdyidtsdnD-ZP77t", slug: "passer-de-zero-a-heros" },
    { id: 3, src: video3, youtubeLink: "https://youtu.be/4Gh68bCdDa4?si=uPdFnCJOFyYviFKW", slug: "devenir-riche" },
    { id: 4, src: video4, youtubeLink: "https://youtu.be/knZEWjxIqv0?si=53Ia9R9Ks2ZkdUTK", slug: "victimes-de-la-flemme" },
    { id: 5, src: video5, youtubeLink: "https://youtu.be/WWUXB9T-zS0?si=4009fVF77XBXvNJu", slug: "faire-le-maximum" },
  ];
  const getLocalizedPath = (slug) => {
    const prefix = i18n.language === "fr" ? "projets" : "projects";
    return `/${prefix}/${slug}`;
  };
  return (
    <div className="container-home-content">
      {videos.map((video) => (
        <Link to={getLocalizedPath(video.slug)} key={video.id} className="video-container-home">
          <video
            autoPlay
            loop
            muted
            playsInline
            className="video-container-home"
            src={video.src}
          ></video>
        </Link>
      ))}
    </div>
  );
}