import './App.css';
import React from "react";
import {Route, Routes} from "react-router-dom";
import Landing from './components/Landing/Landing';
import Home from './components/Home/Home';
import NotFound from './components/404/404';
import About from './components/About/About';
import Projects from './components/Projects/Projects';
import ProjectDetail from './components/Projects/ProjectDetail';
import Contact from './components/Contact/Contact';
import Mention from './components/Legal/Mention';
import Policy from './components/Legal/Policy';
import Events from './components/Events/Events';
import Shop from './components/Shop/Shop';
import ScrollToTop from './components/Utils/ScrollToTop';
import { useTranslation } from 'react-i18next';

function App() {
  const { t } = useTranslation();
  return (
    <div className="App">
      <ScrollToTop />
      <Routes>
      
        <Route path='*' element={<NotFound />} />
        <Route path='/' element={<Landing />} />
        <Route path={`/${t("routes.home")}`} element={<Home />} />
        <Route path={`/${t("routes.about")}`} element={<About />} />
        <Route path={`/${t("routes.projects")}`} element={<Projects />} />
        {/* <Route path={`${t("routes.projects")}/:slug`} element={<ProjectDetail />} /> */}
        <Route path="projets/:slug" element={<ProjectDetail />} />
        <Route path="projects/:slug" element={<ProjectDetail />} />
        <Route path={`/${t("routes.contact")}`} element={<Contact />} />
        <Route path={`/${t("routes.events")}`} element={<Events />} />
        <Route path={`/${t("routes.shop")}`} element={<Shop />} />
        <Route path={`/${t("routes.legal_mentions")}`} element={<Mention />} />
        <Route path={`/${t("routes.privacy_policy")}`} element={<Policy />} />
      </Routes>
    </div>
  );
}

export default App;
