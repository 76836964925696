import React from "react";
import "../../styles/ProjectGrid.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import vignette1 from "../../assets/images/vignettes/1.jpg";
import vignette2 from "../../assets/images/vignettes/2.jpg";
import vignette3 from "../../assets/images/vignettes/3.jpg";
import vignette4 from "../../assets/images/vignettes/4.jpg";
import vignette5 from "../../assets/images/vignettes/5.jpg";

export default function ProjectGrid() {
  const projects = [
    { id: 1, image: vignette1, title: "Comment devenir un artiste ? | Cinematic vlog", slug: "comment-devenir-un-artiste", youtubeLink: "https://www.youtube.com/embed/xglnlVhrDM4" },
    { id: 2, image: vignette2, title: "Passer de zéro à héros | Nekketsu épisode 0", slug: "passer-de-zero-a-heros", youtubeLink: "https://www.youtube.com/embed/Hnzj0fIuD1s" },
    { id: 3, image: vignette3, title: "Venez on essaye de devenir riche... | Nekketsu 1", slug: "devenir-riche", youtubeLink: "https://www.youtube.com/embed/4Gh68bCdDa4" },
    { id: 4, image: vignette4, title: "À nous, les victimes de la flemme | Nekketsu épisode 2", slug: "victimes-de-la-flemme", youtubeLink: "https://www.youtube.com/embed/knZEWjxIqv0" },
    { id: 5, image: vignette5, title: "Faire le maximum en 1 nuit | Nekketsu Hors-série #1", slug: "faire-le-maximum", youtubeLink: "https://www.youtube.com/embed/WWUXB9T-zS0" },
  ];
  const reversedProjects = projects.reverse();
  const { t, i18n } = useTranslation();

  const getLocalizedPath = (slug) => {
    const prefix = i18n.language === "fr" ? "projets" : "projects";
    return `/${prefix}/${slug}`;
  };

  return (
    <div className="project-grid">
      {reversedProjects.map((project) => (
        <Link
          to={getLocalizedPath(project.slug)}
          key={project.id}
          className="project-item"
        >
          <img src={project.image} alt={project.title} className="project-image" />
          <div className="project-info">
            <h3>{project.title}</h3>
            <span className="btn-youtube">{t("project.button.view")}</span>
          </div>
        </Link>
      ))}
    </div>
  );
}