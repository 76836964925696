import React from "react";
import "../../styles/Contact.css";
import video from "../../assets/videos/lastvideo.mp4";
import ContactForm from "./ContactForm";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

export default function Contact() {
    return (
        <div className="container-contact">
            <Header />
            <div className="container-video-contact">
                <video src={video} autoPlay loop muted playsInline />
            </div>
            <ContactForm />
            <Footer />
        </div>
    );
}