import React from "react";
import "../../styles/404.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import logo from "../../assets/images/404.svg";
import { useTranslation } from "react-i18next";

export default function NotFound() {
    const { t } = useTranslation();
    return (
        <div className="container-404">
            <Header />
            <div className="content-404">
            <div className="logo-404">
                <img src={logo} alt="404" className="logo-404"/>
            </div>
            <div className="message-container-404">
                <h2 className="message-404">{t("404.description")}</h2>
            </div>
            </div>
            <Footer />
        </div>
    );
}