import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useParams } from "react-router-dom";
import "../../styles/ProjectDetail.css";

const projects = [
  {
    slug: "comment-devenir-un-artiste",
    title: "Comment devenir un artiste ? | Cinematic vlog",
    embed: `<iframe width="560" height="315" src="https://www.youtube.com/embed/xglnlVhrDM4?si=yHNXuB71W37q65hr" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
  },
  {
    slug: "passer-de-zero-a-heros",
    title: "Passer de zéro à héros | Nekketsu épisode 0",
    embed: `<iframe width="560" height="315"src="https://www.youtube.com/embed/Hnzj0fIuD1s?si=EwQqq9ak7ps7RktW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
  },
  {
    slug: "devenir-riche",
    title: "Venez on essaye de devenir riche... | Nekketsu 1",
    embed: `<iframe width="560" height="315" src="https://www.youtube.com/embed/4Gh68bCdDa4?si=a85EjbCOLB_n1MH0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
  },
  {
    slug: "victimes-de-la-flemme",
    title: "À nous, les victimes de la flemme | Nekketsu épisode 2",
    embed: `<iframe width="560" height="315" src="https://www.youtube.com/embed/knZEWjxIqv0?si=N8khTFvsp7z87KnB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
  },
  {
    slug: "faire-le-maximum",
    title: "Faire le maximum en 1 nuit | Nekketsu Hors-série #1",
    embed: `<iframe width="560" height="315" src="https://www.youtube.com/embed/WWUXB9T-zS0?si=JuLobQPIMuJJmfvy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
  },
];

export default function ProjectDetail() {
  const { slug } = useParams();
  const project = projects.find((p) => p.slug === slug);

  if (!project) {
    return <p>Projet non trouvé.</p>;
  }
  return (
    <div className="container-project-detail">
        <Header />
      <div className="video-container-detail">
        <div
          dangerouslySetInnerHTML={{ __html: project.embed }}
          className="youtube-iframe"
        ></div>
      </div>
      <h1>{project.title}</h1>
        <Footer />
    </div>
  );
}