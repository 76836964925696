import React from "react";
import "../../styles/TitleBanner.css";
import { useTranslation } from "react-i18next";

export default function TitleBanner() {
    const { t } = useTranslation();
    return (
        <div className="container-banner-title">
            <h1 className="text-container-banner">{t("project.title")}</h1>
        </div>
    );
}